
import Vue from "vue";
import notification from "@/services/notificationService";
import { Settings } from "luxon";
import timezones from "@/shared/data/timezones";
import lodash from "lodash";
import { tripCategory, userRoles } from "@/shared/models";

export default Vue.extend({
  name: "Settings",
  data() {
    return {
      tripCategory,
      userRoles,
      timezones: lodash.sortBy(timezones, ["name"]),
      general: {
        userTimezone: Settings.defaultZoneName,
        userVehicleTripCategorySettings: undefined
      },
      isSaving: false,
      changesSaved: false
    };
  },
  computed: {
    vehicleTripCategorySettings() {
      return this.$store.state.vehicleTripCategorySettings;
    },
    currentUserRole() {
      return this.$store.state.currentUserRole;
    }
  },
  watch: {
    vehicleTripCategorySettings: function () {
      if (this.vehicleTripCategorySettings) {
        this.general.userVehicleTripCategorySettings = this.vehicleTripCategorySettings;
      }
    }
  },
  methods: {
    setTimezone() {
      Settings.defaultZoneName = this.general.userTimezone;
    },
    setUserVehicleTripCategorySettings() {
      this.$store.dispatch(
        "updateVehicleTripCategorySettings",
        this.general.userVehicleTripCategorySettings
      );
    },
    async saveChanges() {
      this.changesSaved = false;
      this.isSaving = true;
      await this.setTimezone();
      this.isSaving = false;
      this.changesSaved = true;
    }
  }
});
