var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column p-5"},[_c('div',{staticClass:"p-3 p-3"},[_vm._m(0),_c('div',{staticClass:"box"},[_c('b-tabs',[_c('b-tab-item',{attrs:{"value":"general","label":"General","icon":"cogs"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Time Zone"}},[_c('b-select',{attrs:{"placeholder":"Select a Time Zone"},model:{value:(_vm.general.userTimezone),callback:function ($$v) {_vm.$set(_vm.general, "userTimezone", $$v)},expression:"general.userTimezone"}},[_c('optgroup',{attrs:{"label":"Selected"}},[_c('option',{domProps:{"value":_vm.general.userTimezone}},[_vm._v(" "+_vm._s(_vm.general.userTimezone)+" ")])]),_c('optgroup',{attrs:{"label":"Time Zones"}},_vm._l((_vm.timezones),function(timezone){return _c('option',{key:timezone.name,domProps:{"value":timezone.name}},[_vm._v(" "+_vm._s(timezone.name)+" ")])}),0)])],1)],1)]),(_vm.currentUserRole === this.userRoles.Admin)?_c('div',[(_vm.general.userVehicleTripCategorySettings)?_c('div',[_c('hr'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Defaults"}},[_c('p',[_vm._v(" Business, private and unmarked trips will be marked by default according to your selection ")])]),_c('div',{staticClass:"columns mt-0"},[_c('div',{staticClass:"column is-2 text-bold text-small"},[_vm._v(" Vehicle Type ")]),_c('div',{staticClass:"column text-bold text-small"},[_vm._v(" Default Trip Category ")])]),_vm._l((this.general
                        .userVehicleTripCategorySettings),function(setting,index){return _c('div',{key:index},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_vm._v(" "+_vm._s(_vm.general.userVehicleTripCategorySettings[index] .vehicleType)+" ")]),_c('div',{staticClass:"column is-2"},[_c('b-select',{attrs:{"expanded":"","placeholder":"Select a trip category"},model:{value:(
                              _vm.general.userVehicleTripCategorySettings[index]
                                .tripCategory
                            ),callback:function ($$v) {_vm.$set(_vm.general.userVehicleTripCategorySettings[index]
                                , "tripCategory", $$v)},expression:"\n                              general.userVehicleTripCategorySettings[index]\n                                .tripCategory\n                            "}},[_c('option',{domProps:{"value":_vm.tripCategory.Business}},[_vm._v(" Business ")]),_c('option',{domProps:{"value":_vm.tripCategory.Private}},[_vm._v(" Private ")]),_c('option',{domProps:{"value":_vm.tripCategory.Unmarked}},[_vm._v(" Unmarked ")])])],1)])])})],2)])]):_vm._e()]):_vm._e()])],1),_c('hr'),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-right"},[(_vm.changesSaved)?_c('div',{staticClass:"has-text-info"},[_vm._m(1)]):_vm._e()]),_c('div',{staticClass:"level-left"},[_c('b-button',{attrs:{"type":"is-success","loading":_vm.isSaving},on:{"click":_vm.saveChanges}},[_vm._v("Save Changes")])],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"title is-5 mt-2"},[_vm._v("Settings")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('i',{staticClass:"mdi mdi-check pr-1"}),_vm._v("Changes saved")])
}]

export { render, staticRenderFns }